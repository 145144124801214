module.exports = {
	colors: {
		main: '#013f72',
		sec: '#ffa300',
		alt: '#013f72',
	},

	showFunds: false,

	showBuses: false,

	about: {
		mobiles: ['0936885000', '0995444100'],
		phones: [],
		address: 'حلب الجديدة - الراشدين - بعد دوار باب السلام بـ 2 km',
		data: [0, 0],
		email: null,
		facebook: 'https://www.facebook.com/profile.php?id=61550561609767&mibextid=ZbWKwL',
		privacy: 'https://rowadalmustakbal.edunixsystem.com/policy',
		details: [
			{
				title: 'مزايـا المدرسة',
				content: [
					'مدرستنا هي الأقرب إلى مدينة حلب حيث تبعد عن دوار باب السلام 2 كم.',
					'مرافق وملاعب ترفيهية تحقق توازناً بين التعليم والترفيه (ألعاب داخلية - مسبح - ملعب كرة قدم - ملعب كرة سلة).',
					'تواصل مع الأهالي عبر برامج تواصل واشعارات لتعزيز نجاح عملية التربية والتعليم.',
					'مخابر علوم وكيمياء مميز جداً بالإضافة لمخبر المعلوماتية والمكتبة لشرح المعلومة بحيث تكون ملامسة للواقع العملي.',
					'بيئة آمنة نفسياً وتحقق كل الشروط الصحية من حيث المساحة والتهوية والإضاءة والمناخ الجيد.',
					'كافتيريا تحتوي أصناف عديدة من الأطعمة والعصائر لتأمين احتياجات الطلاب الصحية.',
					'عيادة طبيب لعلاج الحالات الطارئة والإجراءات السنوية الوقائية كفحص العيون والأسنان.',
					'مرشد نفسي تربوي لعلاج الحالات السلوكية والاجتماعية والأخلاقية ومتابعتها مع الطلاب.',
					'معايير تحفيز القدرات الإبداعية لدى الموهوبين.',

				],
			},
			{
				title: 'من نحن',
				content: [
					'نحن مؤسسة تربوية تعليمية نؤمن بقيمة العلم والمعرفة ودورها في صناعة الحضارة الإنسانية.',
					'وانطلاقاً من هذا الإحساس بالمسؤولية كان مشروعنا رواد المستقبل الخاصة أن نعمل على ذلك ونأمل بمساندتكم أن ينمو ويزدهر ليصبح منارة تعليمية حقيقية.',
				],
			},
			{
				title: 'رؤيتنا',
				content: [
					'تأمين متطلبات النمو للطفولة المبكرة والمساعدة في تنمية المهارات الأساسية اللازمة للنجاح في الحياة لجميع الطلاب.',
					'تزويد الطلاب بالمعارف والقيم والمهارات اللازمة للاستمرار في البحث العلمي.',
					'ربط المؤسسة بالقيم الأساسية للمجتمع وتطلعاته للتقدم والتطور.',
				],
			},
			{
				title: 'رسالة المدرسة',
				content: [
					'إعداد جيل قادر على العيش وفق المتغيرات الدائمة.',
					'ممتلك لمهارات ومعارف تمكنه من النجاح في حياته وتحقيق السعادة له وللآخرين.',
					'غرس القيم الروحية وإحياء القيم الوطنية والدينية والوجدانية في نفوس الطلاب.',
				],
			},
		],
	},

	pwa: {
		name: 'Futrue Pioneers School',
		shortName: 'FPS',
		description: 'FPS app - portal version for FPS mobile app.',
	},
};
