<template>
	<div
		style="height: 100vh"
		class="d-flex justify-center align-center"
		v-if="loading">
		<Loader></Loader>
	</div>

	<div
		v-else
		class="fill-hight d-flex align-center"
		style="height: calc(100vh - 50px)"
		fluid>
		<div class="header-image">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 375 65.97"
				style="
					shape-rendering: geometricPrecision;
					text-rendering: geometricPrecision;
					image-rendering: optimizeQuality;
					fill-rule: evenodd;
					clip-rule: evenodd;
				"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<g>
					<path d="M0,0H375S233.21,67.86,117.9,65.93,0,0,0,0Z" />
				</g>
			</svg>
		</div>
		<div class="page-container mx-auto px-2">
			<div style="z-index: 4; position: relative">
				<!-- logo -->
				<router-link
					:to="{ name: 'about' }"
					class="d-inline-block">
					<v-img
						src="../assets/Logo.png"
						height="5.5rem"
						max-width="5.5rem"
						alt="logo"
						contain></v-img>
				</router-link>
				<div class="d-flex justify-space-between align-center mb-4">
					<v-btn
						text
						@click="loginMenu = !$isOffline.value"
						class="font-weight-regular">
						<v-icon left> mdi-account-circle </v-icon>
						مرحبا, {{ activeUser.userName }}
					</v-btn>

					<v-btn
						v-if="showNotificationBtn"
						icon
						color="grey darken-3"
						@click="checkNotification">
						<v-icon>mdi-bell-ring</v-icon>
					</v-btn>
				</div>

				<!-- cards -->
				<div
					class="mx-auto"
					style="max-width: 500px">
					<!-- exames card -->
					<v-card
						:to="{ name: 'exams' }"
						class="right-border big-card px-5 py-3 mb-2"
						rounded="lg"
						elevation="3">
						<div class="card-title homeOrange--text">
							<span>المذاكرات</span>
							<v-img
								v-if="lastExam && diffDate(new Date(lastExam.Date)) >= 0"
								src="../assets/exam.png"
								height="2rem"
								max-width="2rem"
								contain></v-img>
						</div>

						<div
							class="d-flex justify-space-between align-end"
							v-if="lastExam && diffDate(new Date(lastExam.Date)) >= 0">
							<div>
								<div class="text-caption">المذاكرة القدامة</div>
								<div class="text-h4">
									{{ lastExam.SubjectName }}
								</div>
								<div class="mb-2">
									{{ lastExam.Details }}
								</div>
								<div class="d-flex text-caption">
									<div class="ml-1">
										<v-icon
											color="homeOrange"
											small
											>mdi-calendar-blank-outline</v-icon
										>
										{{ dateFormater(new Date(lastExam.Date)) }}
									</div>
									<div>
										<v-icon
											class="ml-1"
											color="homeOrange"
											small
											>mdi-clock-outline</v-icon
										>{{ getTime(new Date(lastExam.StartTime)) }}
										-
										{{ getTime(new Date(lastExam.EndTime)) }}
									</div>
								</div>
							</div>
							<div class="text-center py-3 homeOrange--text">
								<div class="text-h4">
									{{ diffDate(new Date(lastExam.Date)) }}
								</div>
								<div>أيام متبقية</div>
							</div>
						</div>
						<div
							v-else
							class="text-center my-3">
							<v-img
								src="../assets/exam.png"
								height="4rem"
								max-width="4rem"
								contain
								class="mx-auto mb-2"></v-img>
							<span> لا توجد مذاكرات مجدولة </span>
						</div>
					</v-card>

					<v-row
						class="align-stretch mb-2"
						no-gutters>
						<!-- Statistics card -->
						<v-col cols="6">
							<v-card
								height="100%"
								:to="{ name: 'statistics' }"
								class="top-border big-card px-3 py-3 me-2"
								rounded="lg"
								elevation="3">
								<div class="card-title homePink--text mb-1">
									<span
										style="
											font-size: 1.2rem;
											display: inline-block;
											min-width: max-content;
										">
										التقرير الشهري
									</span>
									<v-img
										src="../assets/statistics.png"
										height="2rem"
										max-width="2rem"
										contain></v-img>
								</div>
								<div>
									<span
										v-if="statistics?.MonthlyAverage"
										style="font-size: 2.4rem; line-height: 1"
										class="primary--text font-weight-bold">
										{{ statistics?.MonthlyAverage }}
									</span>
									<span
										v-else
										class="text-h5">
										-
									</span>
								</div>
								<div class="mb-4">عن شهر {{ getMonth(new Date(statistics.Month)) }}</div>
								<div>
									<span class="primary--text text-h4 font-weight-bold">
										{{ statistics?.CountOfTotalMark || 0 }}
									</span>
									<span> علامات تامة</span>
								</div>
								<!-- <div
									class="mt-1"
									v-if="statistics?.SpecialtyRank <= 10">
									ترتيب
									<span class="primary--text text-h4 font-weight-bold">
										{{ statistics?.SpecialtyRank || '-' }}
									</span>
									<span> على الصف</span>
								</div> -->
							</v-card>
						</v-col>

						<v-col cols="6">
							<!-- grades card -->
							<v-card
								:to="{ name: 'grades' }"
								style="height: 50%"
								class="right-border big-card px-5 py-3 pb-4"
								rounded="lg"
								elevation="3">
								<div class="card-title homeYellow--text">
									<span>العلامات</span>
									<v-img
										src="../assets/test.png"
										height="2rem"
										max-width="2rem"
										contain></v-img>
								</div>
								<span v-if="lastMark">
									<template v-if="!lastMark.IsAbsent">
										<span class="primary--text text-h4 font-weight-bold">
											{{ lastMark.Mark }}</span
										>
										\
										<span>{{ lastMark.TestMaxMark }}</span>
									</template>
									<template v-else>
										<span class="primary--text text-h5 font-weight-bold">لم يقدم </span>
									</template>

									<br />
									<span>{{ lastMark.Test?.SubjectName }}</span>
								</span>
								<span v-else>لم يتم تسجيل علامات بعد</span>
							</v-card>

							<!-- attendance card spaceing -->
							<div
								class="pt-2"
								style="height: 50%">
								<!-- attendance card -->
								<v-card
									:to="{ name: 'attendance' }"
									style="height: 100%"
									class="right-border big-card px-5 py-3"
									rounded="lg"
									elevation="3">
									<div class="card-title homeBlue--text">
										<span>الحضور</span>
										<v-img
											src="../assets/target.png"
											height="2rem"
											max-width="2rem"
											contain></v-img>
									</div>
									<span v-if="attendance.StudentRate">
										<span class="primary--text text-h4 font-weight-bold">{{
											attendance.StudentRate
										}}</span>
										\
										<span>{{ attendance.TotalRate }}</span>
									</span>
									<span
										v-else
										class="text-h5">
										-
									</span>
								</v-card>
							</div>
						</v-col>
					</v-row>

					<v-row no-gutters>
						<!-- Schedule card -->
						<v-col :cols="lastRowCol">
							<v-card
								:to="{ name: 'schedule' }"
								style="height: 100%"
								class="top-border big-card py-4 me-1"
								:class="config.showFunds || config.showBuses ? 'px-2 py-4' : 'px-5 py-7'"
								rounded="lg"
								elevation="3">
								<div class="card-images mb-1">
									<v-img
										src="../assets/schedule.png"
										height="1.6rem"
										max-width="1.6rem"
										contain></v-img>
								</div>
								<span
									:class="
										config.showFunds || config.showBuses ? 'text-caption' : 'text-body-1'
									"
									>برنامج الدوام</span
								>
							</v-card>
						</v-col>

						<!-- behavior notes card -->
						<v-col :cols="lastRowCol">
							<v-card
								:to="{ name: 'behavior' }"
								style="height: 100%"
								class="top-border big-card py-4 mx-1"
								:class="config.showFunds || config.showBuses ? 'px-2 py-4' : 'px-5 py-7'"
								rounded="lg"
								elevation="3">
								<div class="card-images mb-1">
									<v-img
										src="../assets/notes.png"
										height="1.6rem"
										max-width="1.6rem"
										contain></v-img>
								</div>
								<span
									:class="
										config.showFunds || config.showBuses ? 'text-caption' : 'text-body-1'
									">
									الملاحظات السلوكية
								</span>
							</v-card>
						</v-col>

						<!-- contacts(chat) card -->
						<v-col :cols="lastRowCol">
							<v-card
								:to="{ name: 'chat' }"
								style="height: 100%"
								class="top-border big-card py-4 ms-1"
								:class="config.showFunds || config.showBuses ? 'px-2 py-4' : 'px-5 py-7'"
								rounded="lg"
								elevation="3">
								<div class="card-images mb-1">
									<v-img
										src="../assets/chat.png"
										height="1.6rem"
										max-width="1.6rem"
										contain></v-img>
								</div>
								<span
									:class="
										config.showFunds || config.showBuses ? 'text-caption' : 'text-body-1'
									">
									التواصل مع الإدارة
								</span>
							</v-card>
						</v-col>

						<!-- funds card -->
						<v-col
							:cols="lastRowCol"
							v-if="config.showFunds">
							<v-card
								:to="{ name: 'funds' }"
								style="height: 100%"
								class="top-border big-card py-4 ms-2"
								:class="config.showFunds || config.showBuses ? 'px-2 py-4' : 'px-5 py-7'"
								rounded="lg"
								elevation="3">
								<div class="card-images mb-1">
									<v-img
										src="../assets/funds.png"
										height="1.6rem"
										max-width="1.6rem"
										contain></v-img>
								</div>
								<span
									:class="
										config.showFunds || config.showBuses ? 'text-caption' : 'text-body-1'
									">
									الدفعات
								</span>
							</v-card>
						</v-col>

						<!-- buses card -->
						<v-col
							:cols="lastRowCol"
							v-if="config.showBuses">
							<v-card
								:to="{ name: 'bus' }"
								style="height: 100%"
								class="top-border big-card py-4 ms-2"
								:class="config.showFunds || config.showBuses ? 'px-2 py-4' : 'px-5 py-7'"
								rounded="lg"
								elevation="3">
								<div class="card-images mb-1">
									<v-img
										src="../assets/bus.png"
										height="1.6rem"
										max-width="1.6rem"
										contain></v-img>
								</div>
								<span
									:class="
										config.showFunds || config.showBuses ? 'text-caption' : 'text-body-1'
									">
									الموصلات
								</span>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</div>

			<v-footer
				app
				class="justify-space-between footer transparent">
				v{{ version.version }}
				<a
					href="https://edunix.beetronix.net"
					target="_blank">
					<v-img
						href=""
						src="../assets/Powered By Edunix.png"
						contain
						height="2.6rem"
						max-width="6rem"></v-img>
				</a>
				<div class="footer-image">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 650 215"
						style="
							shape-rendering: geometricPrecision;
							text-rendering: geometricPrecision;
							image-rendering: optimizeQuality;
							fill-rule: evenodd;
							clip-rule: evenodd;
						"
						xmlns:xlink="http://www.w3.org/1999/xlink">
						<g>
							<path
								d="M 108.5,-0.5 C 137.167,-0.5 165.833,-0.5 194.5,-0.5C 270.014,2.8347 343.681,16.668 415.5,41C 495.13,70.3827 563.963,115.883 622,177.5C 632.458,189.444 642.291,201.778 651.5,214.5C 651.5,214.833 651.5,215.167 651.5,215.5C 434.167,215.5 216.833,215.5 -0.5,215.5C -0.5,147.5 -0.5,79.5 -0.5,11.5C 35.7793,5.70683 72.1126,1.70683 108.5,-0.5 Z" />
						</g>
					</svg>
				</div>
			</v-footer>

			<v-bottom-sheet v-model="loginMenu">
				<v-sheet class="pa-3 rounded-t-lg">
					<v-list>
						<v-list-item
							v-for="user in users"
							:key="user.userName">
							<v-list-item-content @click="login(user)">
								<v-list-item-title>
									{{ user.userName }}
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon
								@click="
									openLogoutDialog = true;
									userToLogout = user;
								">
								<v-icon color="myOrange"> mdi-logout </v-icon>
							</v-list-item-icon>
						</v-list-item>
					</v-list>
					<div class="d-flex justify-space-between">
						<v-btn
							color="primary"
							text
							@click="loginMenu = false">
							إلغاء
						</v-btn>
						<v-btn
							color="primary"
							:to="{ name: 'login' }">
							اضافة حساب
						</v-btn>
					</div>
				</v-sheet>
			</v-bottom-sheet>

			<v-dialog
				v-model="openLogoutDialog"
				width="400">
				<v-card
					class="pa-6"
					rounded="lg">
					<p class="text-center mb-8 text-h6">هل تريد تسجيل الخروج؟</p>
					<v-row>
						<v-col cols="6">
							<v-btn
								color="primary"
								block
								@click="
									openLogoutDialog = false;
									userToLogout = null;
								">
								إلغاء
							</v-btn>
						</v-col>
						<v-col cols="6">
							<v-btn
								color="primary"
								text
								block
								@click="
									openLogoutDialog = false;
									logout(userToLogout);
								">
								تاكيد
							</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { dateFormater, diffDate, getTime, getMonth } from '@/helpers/helpers';
import { mapState } from 'vuex';
import config from '@/helpers/config';
import stores from '@/idb/stores';
import * as idb from 'idb';
import { isNotificationsSupported } from '@/firebase/messaging';

export default {
	name: 'Home',

	components: {
		Loader,
	},

	data() {
		return {
			loading: true,
			loginMenu: false,

			openLogoutDialog: false,
			userToLogout: null,

			config,

			showNotificationBtn: false,
		};
	},

	computed: {
		lastRowCol() {
			if (this.config.showFunds && this.config.showBuses) return 'five';
			if (this.config.showFunds || this.config.showBuses) return 3;
			return 4;
		},
		...mapState({
			users: (state) => state.login.users,
			activeUser: (state) => state.login.activeUser,
			attendance: (state) => state.home.attendance,
			statistics: (state) => state.home.statistics,
			lastMark: (state) => state.home.lastMark,
			version: (state) => state.home.version,
			lastExam: (state) => state.exams.lastExam,
		}),
	},

	methods: {
		dateFormater,
		diffDate,
		getTime,
		getMonth,
		resetIDB() {
			const dbPromise = idb.openDB('api-response', 1);
			const clearAll = (storeName) =>
				dbPromise.then((db) => {
					const tx = db.transaction(storeName, 'readwrite');
					tx.store.clear();
					return tx.done;
				});
			const promises = [];
			Object.keys(stores).forEach((storeKey) => {
				promises.push(clearAll(stores[storeKey].name));
			});
			promises.push(clearAll('objects'));
			return Promise.all(promises).catch(() => {});
		},
		login(user) {
			// if try to log to the same user ignor
			if (this.activeUser.userName === user.userName) {
				this.loginMenu = false;
				return;
			}

			this.loading = true;
			this.loginMenu = false;
			this.$store
				.dispatch('login/login', {
					userName: user.userName,
					password: user.password,
				})
				.then(() => {
					this.$store.dispatch('clearAll');
					this.resetIDB();
					this.fetch();
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.dispatch('login/logout', { user });
						this.$eventBus.$emit('show-snackbar', true, 'تم تعديل معلومات الحساب او حذفه');
						this.$router.push({ name: 'accounts' });
					} else {
						this.$eventBus.$emit('show-snackbar', true, 'حدث خطأ ما. اعد المحاولة لاحقا');
					}
				});
		},
		logout(user) {
			const activeUser = this.activeUser;
			this.$store.dispatch('login/logout', { user });
			this.$eventBus.$emit('show-snackbar', false, 'تم تسجيل الخروج');
			if (user.userName === activeUser.userName) {
				this.$store.dispatch('clearAll');
				this.resetIDB();
				if (this.users.length) this.$router.push({ name: 'accounts' });
				else this.$router.push({ name: 'login' });
			}
			this.userToLogout = null;
			this.loginMenu = false;
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('home/fetchAttendance'),
				this.$store.dispatch('home/fetchStatistics'),
				this.$store.dispatch('home/fetchLastMark'),
				this.$store.dispatch('home/fetchVersion'),
				this.$store.dispatch('exams/fetchLast'),
			]).finally(() => {
				this.loading = false;
			});
		},

		checkNotification() {
			Notification.requestPermission().then((result) => {
				if (result === 'granted') {
					this.$router.push({ name: 'notifications' });
				} else {
					alert(
						'الرجاء منح التطبيق سماحية استخدام التنبيهات والتأكد أنك لا تستخدم وضع التصفح المتخفي أو الخاص.',
					);
				}
			});
		},
	},

	created() {
		isNotificationsSupported().then((supported) => {
			if (supported) {
				this.showNotificationBtn = true;
				this.$store.dispatch('notifications/fetchAll');
			}
		});
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style scoped lang="scss">
$border: 4px solid var(--v-primary-base);
.header-image {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	max-height: 100px;
	svg {
		width: 100%;
		max-height: 100px;
		fill: var(--v-alt-base);
	}
}
.footer-image {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 300px;
	z-index: -1;
	@media (max-width: 576px) {
		width: 250px;
	}
	svg {
		display: block;
		margin-bottom: -2px;
		fill: var(--v-alt-base);
	}
}
.card-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 0.2rem;
	span {
		font-size: 1.5rem;
		font-weight: bold;
	}
}
.right-border {
	position: relative;
	overflow: hidden;
	padding-right: 20px !important;
	&::before {
		opacity: 1;
		content: '';
		height: 100%;
		width: 10px;
		background-color: var(--v-alt-base);
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
}
.top-border {
	position: relative;
	overflow: hidden;
	padding-top: 20px !important;
	&::before {
		opacity: 1;
		content: '';
		height: 10px;
		width: 100%;
		background-color: var(--v-alt-base);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}
</style>
