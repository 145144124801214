import { notificationTypes } from '@/helpers/enums';
import store from '@/store';

export const notificationOptions = {
	icon: '/logo.png',
	dir: 'rtl',
	lang: 'ar-SA',
};

export const notificationPageRedirect = [
	{ type: notificationTypes.message, redirect: '/chat', routeName: 'chat' },
	{ type: notificationTypes.attendance, redirect: '/attendance', routeName: 'attendance' },
	{ type: notificationTypes.newMark, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.newMarks, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.schedule, redirect: '/schedule', routeName: 'schedule' },
	{ type: notificationTypes.newTest, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.testsSchedule, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.transport, redirect: '/', routeName: 'home' },
	{ type: notificationTypes.notes, redirect: '/behavior', routeName: 'behavior' },
];

export const firebase = {
	configs: {
		apiKey: 'AIzaSyAA1M8jrNKunBckq8UpCbTM0rpPnw-KcOE',
		authDomain: 'edunix-mobile.firebaseapp.com',
		projectId: 'edunix-mobile',
		storageBucket: 'edunix-mobile.appspot.com',
		messagingSenderId: '604727235405',
		appId: '1:604727235405:web:666bd31302d846da0e5d04',
	},
	publicKey: 'BA99LUFb1HIlUEg1XFmvmOyk6s99Buxq2pQP5AtQstEf-r08giMRJ6gXPNl80KIPPN48AJhxUmh51K0FOS9ArNo',
};

export const onGetNotification = () => {
	store.dispatch('notification/fetchAll');
};
