<template>
	<v-app>
		<div
			@click="$router.go()"
			class="offline"
			v-if="$isOffline.value">
			انت غير متصل بالانترنت
			<v-icon color="white">mdi-refresh</v-icon>
		</div>
		<v-app-bar
			v-if="!['home', 'login', 'accounts'].includes($route.name)"
			app
			color="primary"
			dark>
			<v-btn
				icon
				text
				@click="$router.back()">
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-toolbar-title class="mr-n12 text-h6">
				{{ titles[$route.name] || 'عنوان' }}
			</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-app-bar>

		<div
			class="d-flex justify-center align-center"
			style="height: 100vh"
			v-if="loading && !['login', 'accounts'].includes($route.name)">
			<Loader></Loader>
		</div>
		<v-main
			app
			v-else>
			<div
				v-if="loadingPages"
				class="d-flex justify-center align-center"
				style="height: 90vh">
				<Loader></Loader>
			</div>
			<router-view v-show="!loadingPages" />
		</v-main>
		<v-snackbar
			text
			shaped
			v-model="showSnackbar"
			:color="isError ? 'error' : 'myGreen'">
			<div class="text-center">
				{{ message }}
			</div>
		</v-snackbar>
	</v-app>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { VERSION } from '@/helpers/constants';

export default {
	name: 'App',

	components: {
		Loader,
	},

	data: () => ({
		message: '',
		isError: true,
		showSnackbar: false,
		loading: true,
		loadingPages: false,

		titles: {
			home: 'الرئيسية',
			'log-in': 'تسجيل الدخول',
			grades: 'العلامات',
			'grade-view': 'علامة',
			exams: 'المذاكرات',
			'exam-program': 'برنامج المذاكرات',
			statistics: 'الاحصائيات',
			schedule: 'برنامج الدوام',
			chat: 'التواصل مع الإدارة',
			behavior: 'الملاحظات السلوكية',
			attendance: 'الحضور',
			about: 'من نحن',
			funds: 'الدفعات',
			notifications: 'الإشعارات',
			bus: 'الموصلات',
		},
		VERSION,
	}),

	created() {
		console.log(`💙 Eduinx ${VERSION} 💙`);
		this.$eventBus.$on('show-snackbar', (isError, message) => {
			this.message = message;
			this.isError = isError;
			this.showSnackbar = true;
		});
		this.$eventBus.$on('loader', (status) => {
			this.loading = status;
			this.$eventBus.$off('loader');
		});
		this.$eventBus.$on('page-loader', (status) => {
			this.loadingPages = status;
			this.$eventBus.$off('loader');
		});

		if (JSON.parse(localStorage.getItem('active-user'))) this.loading = true;
		else this.loading = false;

		// vue-meta
	},
};
</script>

<style lang="scss">
@font-face {
	font-family: Coco;
	src: url('./assets/fonts/normal.otf');
	font-weight: 100 400;
}
@font-face {
	font-family: Coco;
	src: url('./assets/fonts/bold.otf');
	font-weight: 500 900;
}

*:not(.apexchart *),
html {
	font-size: 14px;
}
main {
	background-color: var(--v-white-base);
}
.page {
	padding: 1.5rem 15px;
}
a {
	text-decoration: none;
}
.v-card {
	overflow-wrap: normal !important;
}
.offline {
	position: fixed;
	top: 60px;
	right: 5px;
	left: 5px;
	background-color: #000a;
	color: white;
	text-align: center;
	padding: 10px;
	border-radius: 10px;
	z-index: 100;
}
</style>
